export default [
  {
    path: '/artists/:selectedArtist/:selectedColorId?',
    name: 'Artists'
  },
  { path: '/export/:collectedColorIds?', name: 'Export' },
  { path: '/about', name: 'About' },
  {
    path: '*',
    redirect: '/artists/Andy Warhol'
  }
];
