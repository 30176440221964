<script>
  import ColorInput from "./ColorInput.svelte";
  import CopyButton from "./CopyButton.svelte";

  export let hex;
  export let rgb;
  export let hsl;
  export let textColor;
</script>

<style lang="scss">
  @import "./styles/theme.scss";
  .color-detail__chip {
    width: 100%;
    height: 120px;
    display: flex;
    font-size: $fs-medium;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
  }
  .color-detail__chip span {
    margin-right: 4px;
  }
  .color-detail__row {
    margin-top: $sp-base;
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>

<div class="color-detail">
  <div
    class="color-detail__chip"
    style={`background-color: ${hex}; color: ${textColor}`}>
    <span>{hex}</span>
    <CopyButton text={hex} onDarkBackground={textColor == '#FFF'} />
  </div>
  <div class="color-detail__row">
    <ColorInput id="rgb_r" title="R" value={rgb.r} />
    <ColorInput id="rgb_g" title="G" value={rgb.g} />
    <ColorInput id="rgb_b" title="B" value={rgb.b} />
    <CopyButton text={`${rgb.r},${rgb.g},${rgb.b}`} />
  </div>
  <div class="color-detail__row">
    <ColorInput id="hsl_h" title="H" value={hsl.h} />
    <ColorInput id="hsl_s" title="S" value={hsl.s} />
    <ColorInput id="hsl_l" title="L" value={hsl.l} />
    <CopyButton text={`${hsl.h},${hsl.s},${hsl.l}`} />
  </div>
</div>
