<script>
  import { orderBy, map } from "lodash-es";
  import { collectedColorDetails } from "../data/dataAPI";
  import { collectedColorIds } from "../state/uiState";

  import CollectionItem from "../components/ui/CollectionItem.svelte";
  import Button from "../components/ui/Button.svelte";
  import Tabbar from "../components/ui/Tabbar.svelte";
  import ExportField from "../components/ui/ExportField.svelte";

  let activeSorting = 2;
  const sortingOptions = [
    { name: "Hue", key: "hsl.h" },
    { name: "Satuation", key: "hsl.s" },
    { name: "Light", key: "hsl.l" }
  ];
  const sortingOptionNames = map(sortingOptions, d => d.name);

  $: collectionHasItems = $collectedColorDetails.length !== 0;

  $: sortedColors = orderBy(
    $collectedColorDetails,
    sortingOptions[activeSorting].key
  );
</script>

<style lang="scss">
  @import "./styles/theme.scss";

  .collection {
    width: 100%;
    padding: 30px 20px;
    box-sizing: border-box;
    margin-bottom: 40px;
  }
  h2 {
    font-size: $fs-medium;
    font-weight: $fw-medium;
  }
  .collection__header {
    margin-bottom: 30px;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    justify-content: space-between;
  }
  .collection__header--export {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .collection__export {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .collection__info {
    color: $c-gray-4;
    font-size: 1rem;
  }
</style>

<div class="collection">
  <header class="collection__header">
    <h2>Collected colors ({$collectedColorDetails.length})</h2>
    {#if collectionHasItems}
      <div>
        <Tabbar
          label="Order By"
          options={sortingOptionNames}
          bind:active={activeSorting} />
        <Button
          on:click={() => {
            collectedColorIds.clear();
          }}>
          Remove all
        </Button>
      </div>
    {/if}
  </header>
  {#if collectionHasItems}
    <div class="collection__stage">
      {#each sortedColors as c (c.id)}
        <CollectionItem
          id={c.id}
          hsl={c.hsl}
          hex={c.hex}
          rgb={c.rgb}
          textColor={c.textColor}
          on:remove={d => {
            collectedColorIds.change(d.detail);
          }} />
      {/each}
    </div>
    <header class="collection__header--export">
      <h2>Export</h2>
    </header>
    <div class="collection__export">
      <ExportField text={map(sortedColors, d => d.hex).join(', ')} />
      <ExportField text={map(sortedColors, d => `'${d.hex}'`).join(', ')} />
      <ExportField text={map(sortedColors, d => `0x${d.hexInt}`).join(', ')} />
    </div>
  {:else}
    <div class="collection__info">
      🧑‍🎨 Lets steal some colors and come back!
    </div>
  {/if}
</div>
