<script>
  import { map, filter } from "lodash-es";
  import Router, { link } from "./utils/Router.svelte";
  import { artistLink } from "./state/uiState";

  import routes from "./routes";
  import Artists from "./routes/Artists.svelte";
  import Export from "./routes/Export.svelte";
  import About from "./routes/About.svelte";
  import Loading from "./components/ui/Loading.svelte";

  import Header from "./views/Header.svelte";
  import { isReady } from "./data/dataAPI";

  let activeComponent;
  export let width;
  export let height;

  const components = {
    Artists: { component: Artists, overflow: "hidden" },
    Export: { component: Export, overflow: "auto" },
    About: { component: About, overflow: "auto" }
  };

  let componentRoutes = map(filter(routes, e => e.name != undefined), d => ({
    ...d,
    component: components[d.name].component,
    overflow: components[d.name].overflow
  }));
</script>

<style lang="scss" global>
  @import "./styles/reset.scss";
  @import "./styles/base.scss";
  .app__container {
    overflow: hidden;
    width: 100%;
    height: 100%;
  }
</style>

<div class="app__container">
  {#if $isReady}
    <Header activeItem={activeComponent} />
    <Router
      routes={componentRoutes}
      bind:activeComponent
      viewProps={{ width, height }} />
  {:else}
    <Loading />
  {/if}
</div>
