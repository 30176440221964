<script>
  import { onMount, createEventDispatcher } from "svelte";
  import shader from "../../utils/shaders";

  import {
    Scene,
    PerspectiveCamera,
    WebGLRenderer,
    Color,
    SphereGeometry,
    BufferGeometry,
    BufferAttribute,
    MeshBasicMaterial,
    ShaderMaterial,
    Mesh,
    Group,
    Raycaster,
    Vector2,
    Vector3,
    Matrix4,
    VertexColors,
    BackSide,
    TOUCH,
    Math as ThreeMath
  } from "three";
  import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";

  /* *******************
  /
  / Props
  /
  ******************* */

  export let width = 0;
  export let height = 0;
  export let offsetX = 0;
  export let offsetY = 0;
  export let activeColors = {};
  export let colors = [];
  export let useRelativeSize = false;
  export let selectedColorId = "";

  /* *******************
  /
  / Vars
  /
  ******************* */
  const dispatch = createEventDispatcher();

  // bind element
  let el;

  // three js
  let scene,
    camera,
    renderer,
    group,
    mouse,
    raycaster,
    controls,
    bufferGeometry,
    sphereFaces;

  let renderRequested = false;

  // control helpers
  let currentTimestamp = 0;
  let hasNavigated = false;

  // selection helpers
  let colorIdsToIndex = {};
  let selectionGeometry;
  let selectionMesh;

  /* *******************
  /
  / Functions
  /
  ******************* */

  function init() {
    // render
    renderer = new WebGLRenderer({
      antialias: true,
      powerPreference: "high-performance"
    });
    renderer.setSize(width, height);
    renderer.setPixelRatio(window.devicePixelRatio);

    el.appendChild(renderer.domElement);

    // camera
    camera = new PerspectiveCamera(75, width / height, 0.1, 3000);
    const cameraDistance = Math.min(radius * 3, 1200);
    camera.position.z = cameraDistance;
    //const cameraAngle = 1.35;
    // camera.position.x = Math.cos(cameraAngle) * cameraDistance;
    // camera.position.y = Math.cos(cameraAngle) * cameraDistance;
    // camera.position.z = Math.sin(cameraAngle) * cameraDistance;

    // scene
    scene = new Scene();
    scene.background = new Color(0xf8f8f8);

    //renderer.render(scene, camera);

    // mouse and raycaster
    raycaster = new Raycaster();
    mouse = new Vector2();

    // controls

    controls = new OrbitControls(camera, renderer.domElement);
    //controls.enableDamping = true;
    controls.maxDistance = 2000;
    controls.minDistance = 50;
    controls.update();
    controls.touches = {
      ONE: TOUCH.ROTATE,
      TWO: TOUCH.DOLLY_PAN
    };

    controls.addEventListener("change", requestRenderIfNotRequested);
    controls.addEventListener("start", () => {
      currentTimestamp = timestamp();
      hasNavigated = false;
    });
    controls.addEventListener("end", () => {
      if (timestamp() - currentTimestamp < 0.5) {
        // simple click
        currentTimestamp = timestamp();
      } else {
        hasNavigated = true;
      }
    });

    // initial colors
    group = new Group();
    scene.add(group);

    group.add(createColors(colorsWithPositions));

    // selection sphere
    createSelectionSphere();

    renderer.render(scene, camera);
  }

  function requestRenderIfNotRequested() {
    if (!renderRequested) {
      renderRequested = true;
      requestAnimationFrame(render);
    }
  }

  function render() {
    renderRequested = false;
    renderer.render(scene, camera);
  }

  function timestamp() {
    return Date.now() / 1000;
  }

  function createSphereGeometry(x, y, z, r) {
    const geometry = new SphereGeometry(r, 5, 5);
    geometry.applyMatrix(new Matrix4().makeTranslation(0, height / 2, 0));
    geometry.applyMatrix(new Matrix4().makeRotationX(-ThreeMath.degToRad(90)));
    geometry.applyMatrix(
      new Matrix4().lookAt(
        new Vector3(0, 0, 0),
        new Vector3(x, y, z),
        new Vector3(0, 1, 0)
      )
    );
    geometry.applyMatrix(new Matrix4().makeTranslation(x, y, z));
    return geometry;
  }

  function createSelectionSphere() {
    const positions = new Float32Array(sphereFaces * 3 * 3);

    selectionGeometry = new BufferGeometry();
    selectionGeometry.setAttribute(
      "position",
      new BufferAttribute(positions, 3)
    );

    const selectionMaterial = new MeshBasicMaterial({
      color: 0x000000,
      side: BackSide
    });
    selectionMesh = new Mesh(selectionGeometry, selectionMaterial);
    selectionMesh.visible = false;

    scene.add(selectionMesh);
  }

  function createColors(colorItems) {
    bufferGeometry = new BufferGeometry();
    let positions = 0;
    let colors = 0;
    let visible = 0;

    colorItems.forEach((d, colorIndex) => {
      const geometry = createSphereGeometry(d.x, d.y, d.z, d.r);

      if (positions === 0) {
        let numColors = colorItems.length;
        sphereFaces = geometry.faces.length;
        positions = new Float32Array(numColors * sphereFaces * 3 * 3);
        colors = new Float32Array(numColors * sphereFaces * 3 * 3);
        visible = new Float32Array(numColors * sphereFaces * 3);
      }

      const color = new Color(d.hexInt);
      colorIdsToIndex[d.id] = colorIndex;

      geometry.faces.forEach(function(face, index) {
        let cur_element = colorIndex * sphereFaces + index;

        positions[cur_element * 9 + 0] = geometry.vertices[face.a].x;
        positions[cur_element * 9 + 1] = geometry.vertices[face.a].y;
        positions[cur_element * 9 + 2] = geometry.vertices[face.a].z;
        positions[cur_element * 9 + 3] = geometry.vertices[face.b].x;
        positions[cur_element * 9 + 4] = geometry.vertices[face.b].y;
        positions[cur_element * 9 + 5] = geometry.vertices[face.b].z;
        positions[cur_element * 9 + 6] = geometry.vertices[face.c].x;
        positions[cur_element * 9 + 7] = geometry.vertices[face.c].y;
        positions[cur_element * 9 + 8] = geometry.vertices[face.c].z;

        colors[cur_element * 9 + 0] = color.r;
        colors[cur_element * 9 + 1] = color.g;
        colors[cur_element * 9 + 2] = color.b;
        colors[cur_element * 9 + 3] = color.r;
        colors[cur_element * 9 + 4] = color.g;
        colors[cur_element * 9 + 5] = color.b;
        colors[cur_element * 9 + 6] = color.r;
        colors[cur_element * 9 + 7] = color.g;
        colors[cur_element * 9 + 8] = color.b;

        visible[cur_element * 3 + 0] = 0;
        visible[cur_element * 3 + 1] = 0;
        visible[cur_element * 3 + 2] = 0;
      });
    });

    bufferGeometry.setAttribute("position", new BufferAttribute(positions, 3));
    bufferGeometry.setAttribute("color", new BufferAttribute(colors, 3));
    bufferGeometry.setAttribute("visible", new BufferAttribute(visible, 1));
    bufferGeometry.computeBoundingSphere();

    var shaderMaterial = new ShaderMaterial({
      vertexShader: shader.vertexShader,
      fragmentShader: shader.fragmentShader
    });

    return new Mesh(bufferGeometry, shaderMaterial);
  }

  function handleTouch(event) {
    const ts = timestamp();
    if (
      mouse &&
      raycaster &&
      ((hasNavigated && ts - currentTimestamp > 3) || !hasNavigated)
    ) {
      mouse.x = ((event.changedTouches[0].clientX - offsetX) / width) * 2 - 1;
      mouse.y = -((event.changedTouches[0].clientY - offsetY) / height) * 2 + 1;
      handleSelect();
    }
  }

  function handleClick(event) {
    const ts = timestamp();
    if (
      mouse &&
      raycaster &&
      ((hasNavigated && ts - currentTimestamp > 3) || !hasNavigated)
    ) {
      mouse.x = (event.offsetX / width) * 2 - 1;
      mouse.y = -(event.offsetY / height) * 2 + 1;
      handleSelect();
    }
  }

  function handleSelect() {
    raycaster.setFromCamera(mouse, camera);
    // calculate objects intersecting the picking ray
    const intersects = raycaster.intersectObjects(group.children, true);
    if (intersects.length) {
      for (let i = 0; i < intersects.length; i++) {
        const intersection = intersects[i];
        const index = Math.floor(intersection.faceIndex / sphereFaces);
        if (colors[index] && activeColors[colors[index].id]) {
          dispatch("select", colors[index].id);
          break;
        }
      }
    }
  }

  onMount(() => {
    init();
  });

  /* *******************
  /
  / Reactions
  /
  ******************* */

  $: radius = Math.min(width, height) / 2;
  $: radiusOffset = radius * 0.2;
  $: pointRadius = useRelativeSize ? radius / 20 : Math.max(radius / 40, 10);

  $: colorsWithPositions = colors.map(d => {
    const deg = (d.hsl.h / 360) * Math.PI * 2;
    const dist = radiusOffset + (d.hsl.l / 100) * (radius - radiusOffset);
    const x = Math.cos(deg) * dist;
    const y = Math.sin(deg) * dist;
    const z = (d.hsl.s / 100) * radius;
    const r = pointRadius * 2;
    return { ...d, x, y, z, r };
  });

  // resize
  $: {
    if (renderer) {
      camera.aspect = width / height;
      camera.updateProjectionMatrix();
      renderer.setSize(width, height);
      renderer.render(scene, camera);
    }
  }

  // draw artist
  $: {
    if (renderer !== undefined) {
      const numFace = bufferGeometry.attributes.visible.array.length;
      const vertPerColor = sphereFaces * 3;
      for (let i = 0; i < numFace; i += sphereFaces) {
        const index = Math.floor(i / vertPerColor);
        const color = activeColors[colors[index].id] !== undefined ? 1 : 0;
        for (let j = 0; j < sphereFaces; j++) {
          bufferGeometry.attributes.visible.array[i + j] = color;
        }
      }
      bufferGeometry.attributes.visible.needsUpdate = true;
      renderer.render(scene, camera);
    }
  }

  // selected object
  $: {
    if (selectionGeometry && colorsWithPositions.length) {
      if (
        selectedColorId !== "" &&
        activeColors[selectedColorId] !== undefined
      ) {
        const d = colorsWithPositions[colorIdsToIndex[selectedColorId]];
        const geometry = createSphereGeometry(d.x, d.y, d.z, d.r * 1.2);
        const positions = new Float32Array(sphereFaces * 3 * 3);

        geometry.faces.forEach(function(face, index) {
          selectionGeometry.attributes.position.array[index * 9 + 0] =
            geometry.vertices[face.a].x;
          selectionGeometry.attributes.position.array[index * 9 + 1] =
            geometry.vertices[face.a].y;
          selectionGeometry.attributes.position.array[index * 9 + 2] =
            geometry.vertices[face.a].z;
          selectionGeometry.attributes.position.array[index * 9 + 3] =
            geometry.vertices[face.b].x;
          selectionGeometry.attributes.position.array[index * 9 + 4] =
            geometry.vertices[face.b].y;
          selectionGeometry.attributes.position.array[index * 9 + 5] =
            geometry.vertices[face.b].z;
          selectionGeometry.attributes.position.array[index * 9 + 6] =
            geometry.vertices[face.c].x;
          selectionGeometry.attributes.position.array[index * 9 + 7] =
            geometry.vertices[face.c].y;
          selectionGeometry.attributes.position.array[index * 9 + 8] =
            geometry.vertices[face.c].z;
        });

        selectionMesh.material.color.setHex(d.hexContrastInt);
        selectionGeometry.attributes.position.needsUpdate = true;
        selectionMesh.visible = true;
      } else {
        selectionMesh.visible = false;
      }
      renderer.render(scene, camera);
    }
  }
</script>

<style global>
  :global(.canvas-container canvas) {
    outline: none !important;
  }
</style>

<div
  class="canvas-container"
  bind:this={el}
  on:click={handleClick}
  on:touchend={handleTouch} />
