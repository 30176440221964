<script>
  import ColorCanvas from "../vis/ColorCanvas.svelte";
  export let name;
  export let isActive;
  export let colors = [];
</script>

<style lang="scss">
  @import "./styles/theme.scss";

  .artist {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #eee;
    padding: 10px 20px;
    cursor: pointer;
    background-color: $c-white;
  }
  .artist.is-active,
  .artist:hover {
    font-weight: $fw-medium;
    background-color: #f8f8f8;
  }
  .artist__label {
    padding-left: 10px;
    pointer-events: none;
  }
</style>

<div class="artist" class:is-active={isActive} on:click>
  <ColorCanvas width={75} height={75} {colors} useRelativeSize={true} />
  <div class="artist__label">{name}</div>
</div>
