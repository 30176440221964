<script>
  export let options = [];
  export let active = 0;
  export let label = "";
</script>

<style lang="scss">
  @import "./styles/theme.scss";
  .tab-bar {
    display: inline-flex;
    align-items: baseline;
    margin-right: 10px;
  }
  .tab-bar h4 {
    color: $c-gray-4;
    margin: 0 1rem 0 0;
  }
  .tabs {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .tab {
    border: 1px solid $c-gray-3;
    border-right: none;
    display: inline-block;
    cursor: pointer;
    font-weight: $fw-medium;
    font-size: $fs-base;
    padding: 10px 24px;
    outline: none;
  }
  .tab:first-child {
    border-radius: 4px 0 0 4px;
  }
  .tab:last-child {
    border-radius: 0 4px 4px 0;
    border-right: 1px solid $c-gray-3;
  }
  .tab:hover,
  .tab--active,
  .tab--active:hover {
    background-color: $c-white;
  }
</style>

<div class="tab-bar">
  {#if label}
    <h4>{label}</h4>
  {/if}
  <div class="tabs">
    {#each options as option, index}
      <div
        class={`tab${index === active ? ' tab--active' : ''}`}
        on:click={() => {
          active = index;
        }}>
        {option}
      </div>
    {/each}
  </div>
</div>
