import App from './App.svelte';
import { setInitialRoute } from './utils/Router.svelte';
import routes from './routes';

// initial routes
setInitialRoute(routes);

const app = new App({
  target: document.body
});

export default app;
