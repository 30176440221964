<script>
  export let isBig = false;
  export let tip = "";
</script>

<style lang="scss">
  @import "./styles/theme.scss";
  button {
    width: 24px;
    height: 24px;
    border: none;
    cursor: pointer;
    background-color: $c-white;
    border-radius: 4px;
    padding: 6px;
    box-sizing: border-box;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
  }
  button:focus {
    outline: 0;
  }
  .is-big {
    width: 32px;
    height: 32px;
    background-size: 22px;
  }
  .close {
    position: relative;
    display: inline-block;
    width: 18px;
    height: 18px;
    overflow: hidden;

    &::before,
    &::after {
      content: "";
      position: absolute;
      height: 2px;
      width: 100%;
      top: 50%;
      left: 0;
      margin-top: -1px;
      background: $c-black;
    }
    &::before {
      transform: rotate(45deg);
    }
    &::after {
      transform: rotate(-45deg);
    }
  }
</style>

<button class:is-big={isBig} title={tip} on:click>
  <div class="close" />
</button>
