<script>
  import ColorPile from "./ColorPile.svelte";
  import { contrast, rgbToHex, randomRGB } from "../../utils/colors";

  $: backgroundColor = randomRGB();
  $: backgroundColorHex =
    "#" + rgbToHex(backgroundColor.r, backgroundColor.g, backgroundColor.b);

  $: textColor =
    contrast(backgroundColor, { r: 0, g: 0, b: 0 }) > 7 ? "#000" : "#FFF";
</script>

<style lang="scss">
  @import "../../styles/theme.scss";

  .mobile-disclaimer {
    padding: 0 10%;
    position: relative;
    height: 100%;
    margin: 0 auto;
    max-width: 500px;

    img {
      border: 1px solid $c-gray-3;
      margin-bottom: $sp-base;
    }

    h1 {
      font-size: $fs-medium;
      margin-bottom: $sp-medium;
      span {
        font-size: $fs-small;
        color: $c-gray-4;
        margin-top: -6px;
        margin-left: -2px;
        vertical-align: super;
      }
    }
    p {
      margin-bottom: 1rem;
      color: $c-gray-5;
    }
    p:last-child {
      margin-bottom: 0;
    }
  }
  .mobile-disclaimer__wrapper {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 80%;
    transform: translate(-50%, -50%);
  }
</style>

<div class="mobile-disclaimer">
  <div class="mobile-disclaimer__wrapper">
    <h1>
      <ColorPile
        {textColor}
        backgroundColor={backgroundColorHex}
        tip={backgroundColor}>
        Color
      </ColorPile>
      Theft
      <span>Alpha</span>
    </h1>
    <img src="/img/color-theft.png" alt="Screenshot of Color Theft" />
    <p>
      Hey there! A big sorry 😢, the website is in early development stage. You
      need a minimum screen size of 1024x768 for now. Come back again ... but
      with another device. Thanks and sorry for the inconvenience.
    </p>
  </div>
</div>
