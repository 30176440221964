<script>
  import ArtistList from "../views/ArtistList.svelte";
  import Header from "../views/Header.svelte";
  import Stage from "../views/Stage.svelte";
  import ColorDetails from "../views/ColorDetails.svelte";

  import { selectedColorId } from "../state/uiState";
  import config from "../config";

  export let width = 800;
  export let height = 800;

  $: stageWidth =
    $selectedColorId !== ""
      ? width - config.ARTIST_LIST_WIDTH - config.DETAIL_LIST_WIDTH
      : width - config.ARTIST_LIST_WIDTH;
  $: stageHeight = height - 50;
</script>

<style lang="scss">
  @import "./styles/theme.scss";
  .container {
    width: 100%;
    height: calc(100% - 50px);
    display: flex;
    flex-flow: row wrap;
    align-items: stretch;
    overflow: hidden;
    z-index: 1000;
  }
  .content-container {
    display: flex;
    height: 100%;
  }
  .stage-container {
    position: relative;
    display: flex;
    flex: 1;
  }
</style>

<div class="container">
  <div class="content-container">
    <ArtistList />
    <div class="stage-container">
      <Stage
        width={stageWidth}
        height={stageHeight}
        offsetX={config.ARTIST_LIST_WIDTH}
        offsetY={50} />
      <ColorDetails />
    </div>
  </div>
</div>
