<script>
  export let backgroundColor = "";
  export let textColor = "";
  export let tip = "";
</script>

<style>
  span {
    padding: 3px 9px;
    border-radius: 4px;
    cursor: default;
    margin-right: -2px;
  }
</style>

<span
  title={tip}
  style={`background-color: ${backgroundColor}; color: ${textColor}`}>
  <slot />
</span>
