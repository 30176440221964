import { derived } from 'svelte/store';
import {
  selectedArtist,
  colorDetailLevel,
  selectedColorId,
  collectedColorIds
} from '../state/uiState';

import { imageData } from './dataStore';
import { map, uniqBy, forEach, orderBy, isEmpty, filter } from 'lodash-es';
import { hslToRGB, rgbToHex, hextoHexInt, contrast } from '../utils/colors';
/*
 ------------------ I M A G E S ------------------ 
*/

export const images = derived(imageData, $imageData => $imageData || []);

export const artists = derived(
  images,
  $images => map(uniqBy($images, 'artist'), d => d.artist) || []
);
/*
 ------------------ C O L O R S ------------------ 
*/

function aggregatedColor(color, level) {
  return Math.floor(color / level) * level;
}

export const aggregatedColors = derived(
  [images, colorDetailLevel],
  ([$images, $colorDetailLevel]) => {
    if ($images.length === 0) {
      return {};
    }
    const colors = {};
    forEach($images, image => {
      const localColors = [];
      // colors per image
      forEach(image.colors, c => {
        const h = aggregatedColor(c.h, $colorDetailLevel);
        const s = aggregatedColor(c.s, $colorDetailLevel);
        const l = aggregatedColor(c.l, $colorDetailLevel);

        const rgb = hslToRGB(h, s, l);
        const hex = rgbToHex(rgb.r, rgb.g, rgb.b);
        const colorIndex = `${h}-${s}-${l}`;
        if (colors[colorIndex] === undefined) {
          colors[colorIndex] = {
            id: colorIndex,
            hsl: { h, s, l },
            rgb,
            hex: `#${hex}`,
            hexInt: hextoHexInt(hex),
            artists: {}
          };
        }

        if (colors[colorIndex].artists[image.artist] === undefined) {
          colors[colorIndex].artists[image.artist] = { weight: 0, links: {} };
        }
        colors[colorIndex].artists[image.artist].weight += 1;
        localColors.push(colorIndex);
      });
      // link colors
      forEach(localColors, sourceColor => {
        forEach(localColors, targetColor => {
          if (sourceColor === targetColor) {
            return;
          }
          if (
            colors[sourceColor].artists[image.artist].links[targetColor] ===
            undefined
          ) {
            colors[sourceColor].artists[image.artist].links[targetColor] = 0;
          }
          colors[sourceColor].artists[image.artist].links[targetColor] += 1;
        });
      });
    });
    return colors;
  }
);

export const aggregatedColorsCompact = derived(
  [aggregatedColors],
  ([$aggregatedColors]) => {
    if (isEmpty($aggregatedColors)) {
      return [];
    }
    return map($aggregatedColors, d => ({
      id: d.id,
      hsl: d.hsl,
      hexInt: d.hexInt,
      hexContrastInt:
        contrast(d.rgb, { r: 0, g: 0, b: 0 }) > 3 ? 0x000000 : 0xffea00
    }));
  }
);

export const aggregatedColorsByArtist = derived(
  [aggregatedColors],
  ([$aggregatedColors]) => {
    if (isEmpty($aggregatedColors)) {
      return {};
    }
    const artists = {};
    const artistWeights = {};
    // group by artists
    forEach($aggregatedColors, color => {
      forEach(color.artists, (values, artist) => {
        if (artists[artist] === undefined) {
          artists[artist] = [];
          artistWeights[artist] = 0;
        }
        artists[artist].push({
          id: color.id,
          hsl: color.hsl,
          hex: color.hex,
          //hexInt: color.hexInt,
          weight: values.weight
          //links: values.links
        });
        artistWeights[artist] += values.weight;
      });
    });
    // normalize
    forEach(artists, (colors, artist) => {
      forEach(colors, color => {
        // forEach(color.links, (linkWeight, linkId) => {
        //   color.links[linkId] = linkWeight / color.weight;
        // });
        color.weight /= artistWeights[artist];
      });
      orderBy(colors, c => c.hsl.s, 'asc');
    });
    return artists;
  }
);

export const highlightColor = derived(
  [aggregatedColors, selectedColorId],
  ([$aggregatedColors, $selectedColorId]) => {
    if ($selectedColorId === '') {
      // default color
      return { backgroundColor: '#EAEAEA', textColor: '#000' };
    }

    const color = $aggregatedColors[$selectedColorId];
    const textColor =
      contrast(color.rgb, { r: 0, g: 0, b: 0 }) > 7 ? '#000' : '#FFF';

    return { backgroundColor: color.hex, textColor };
  }
);

export const selectedColorDetails = derived(
  [aggregatedColors, selectedColorId, selectedArtist],
  ([$aggregatedColors, $selectedColorId, $selectedArtist]) => {
    if ($selectedColorId === '') {
      return undefined;
    }
    const selectedColor = $aggregatedColors[$selectedColorId];
    const artists = [];
    // const artistLinks = [
    //   {
    //     id: $selectedColorId,
    //     hex: selectedColor.hex,
    //     hsl: selectedColor.hsl,
    //     weight: 1
    //   }
    // ];
    // let otherLinks = {
    //   $selectedColorId: {
    //     id: $selectedColorId,
    //     hex: selectedColor.hex,
    //     hsl: selectedColor.hsl,
    //     weight: 1
    //   }
    // };
    const artistLinks = [];
    let otherLinks = {};

    forEach(selectedColor.artists, (colors, artist) => {
      if (artist === $selectedArtist) {
        forEach(colors.links, (weight, colorId) => {
          artistLinks.push({
            id: colorId,
            hex: $aggregatedColors[colorId].hex,
            hsl: $aggregatedColors[colorId].hsl,
            weight
          });
        });
        artists.push(artist);
      } else {
        artists.push(artist);
        forEach(colors.links, (weight, colorId) => {
          if (otherLinks[colorId] === undefined) {
            otherLinks[colorId] = {
              id: colorId,
              hex: $aggregatedColors[colorId].hex,
              hsl: $aggregatedColors[colorId].hsl,
              weight: 0,
              artistCount: 0,
              artist: $aggregatedColors[colorId]
            };
          }
          otherLinks[colorId].weight += weight;
          otherLinks[colorId].artistCount += 1;
        });
      }
    });

    const textColor =
      contrast(selectedColor.rgb, { r: 0, g: 0, b: 0 }) > 7 ? '#000' : '#FFF';

    otherLinks = map(otherLinks, d => {
      return {
        id: d.id,
        hex: d.hex,
        hsl: d.hsl,
        weight: d.weight / d.artistCount
      };
    });

    return {
      hsl: selectedColor.hsl,
      rgb: selectedColor.rgb,
      hex: selectedColor.hex,
      textColor: textColor,
      artists,
      artistLinks: orderBy(artistLinks, ['hsl.l', 'hsl.h'], ['asc', 'asc']),
      otherLinks: orderBy(
        filter(orderBy(otherLinks, 'weight'), (d, i) => i < 40),
        ['hsl.l', 'hsl.h'],
        ['asc', 'asc']
      )
    };
  }
);

export const selectedColorInCollection = derived(
  [collectedColorIds, selectedColorId],
  ([$collectedColorIds, $selectedColorId]) => {
    if ($collectedColorIds.length === 0) {
      return false;
    }
    return $collectedColorIds.indexOf($selectedColorId) !== -1;
  }
);

export const collectedColorDetails = derived(
  [collectedColorIds, aggregatedColors],
  ([$collectedColorIds, $aggregatedColors]) => {
    if (isEmpty($aggregatedColors)) {
      return [];
    }

    return map($collectedColorIds, d => {
      const color = $aggregatedColors[d];
      const textColor =
        contrast(color.rgb, { r: 0, g: 0, b: 0 }) > 7 ? '#000' : '#FFF';
      return {
        id: d,
        hsl: color.hsl,
        rgb: color.rgb,
        hex: color.hex,
        hexInt: color.hexInt,
        textColor: textColor
      };
    });
  }
);

/*
 ------------------ A R T I S T S ------------------ 
*/

export const artistsWithColors = derived(
  [artists, aggregatedColorsByArtist, selectedArtist],
  ([$artists, $aggregatedColorsByArtist, $selectedArtist]) => {
    if ($artists.length === 0) {
      return [];
    }
    return map($artists, d => ({
      name: d,
      colors: $aggregatedColorsByArtist[d],
      isSelected: $selectedArtist === d
    }));
  }
);

export const activeArtistColors = derived(
  [aggregatedColorsByArtist, selectedArtist],
  ([$aggregatedColorsByArtist, $selectedArtist]) => {
    if (isEmpty(aggregatedColorsByArtist)) {
      return {};
    }
    const output = {};
    forEach($aggregatedColorsByArtist[$selectedArtist], d => {
      output[d.id] = 1;
    });
    return output;
  }
);

/*
 ------------------ S E T U P ------------------ 
*/

export const isReady = derived(
  [aggregatedColorsByArtist, artistsWithColors, aggregatedColorsCompact],
  ([
    $aggregatedColorsByArtist,
    $artistsWithColors,
    $aggregatedColorsCompact
  ]) => {
    return (
      !isEmpty($aggregatedColorsByArtist) ||
      $artistsWithColors.length !== 0 ||
      $aggregatedColorsCompact.length !== 0
    );
  }
);
