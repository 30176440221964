<script>
  export let isAction = false;
  export let isCompact = false;
  export let cursor = "pointer";
  export let href = "";
  export let tip = "";
</script>

<style lang="scss">
  @import "./styles/theme.scss";
  .button {
    border-radius: 4px;
    border: 1px solid $c-gray-3;
    display: inline-block;
    cursor: pointer;
    font-weight: $fw-medium;
    font-size: $fs-base;
    padding: 10px 24px;
    margin: 0 2px;
    outline: none;
  }
  button:focus {
    outline: 0;
  }
  .button:hover {
    background-color: $c-gray-2;
  }
  .button.is-action {
    border: none;
    color: $c-white;
    background-color: $c-black;
  }
  .button.is-action:hover {
    color: $c-base;
    background-color: $c-gray-3;
  }
  .button.is-compact {
    padding: 5px 12px;
  }
  a.button {
    text-decoration: none;
  }
</style>

{#if href !== ''}
  <a
    {href}
    class="button"
    class:is-compact={isCompact}
    style={`cursor: ${cursor}`}>
    <slot />
  </a>
{:else}
  <button
    class="button"
    class:is-action={isAction}
    class:is-compact={isCompact}
    style={`cursor: ${cursor}`}
    title={tip}
    on:click>
    <slot />
  </button>
{/if}
