<script>
  import { forEach } from "lodash-es";

  export let width;
  export let height;
  export let colors = [];
  export let useRelativeSize = false;

  let canvas;
  let pointScale;
  let colorsWithPositions = [];

  $: radius = Math.min(width, height) / 2;
  $: radiusOffset = radius * 0.2;
  $: pointRadius = radius / 20;
  $: centerX = width / 2;
  $: centerY = height / 2;

  $: {
    if (canvas) {
      const ctx = canvas.getContext("2d");
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      let maxWeight = 0;
      if (useRelativeSize) {
        colors.forEach(d => {
          maxWeight = Math.max(d.weight, maxWeight);
        });
      }

      forEach(colors, d => {
        const deg = Math.PI - (d.hsl.h / 360) * Math.PI * 2 - Math.PI;

        const dist = radiusOffset + (d.hsl.l / 100) * (radius - radiusOffset);
        const x = centerX + Math.cos(deg) * dist;
        const y = centerY + Math.sin(deg) * dist;
        const r = useRelativeSize
          ? Math.max(
              0.5,
              Math.max((d.weight / maxWeight) * pointRadius, pointRadius / 10)
            )
          : pointRadius;

        ctx.fillStyle = d.hex;
        ctx.beginPath();
        ctx.arc(x, y, r, 0, 2 * Math.PI);
        ctx.fill();
      });
    }
  }
</script>

<canvas bind:this={canvas} {width} {height} />
