import { readable } from 'svelte/store';
import { csv } from 'd3-fetch';

export const imageData = readable([], set => {
  csv('data/2-colors.csv', d => {
    const numColors = 5;
    const colorsGroups = [];
    for (let i = 0; i < numColors; i++) {
      if (d['color_' + (i + 1)] !== '') {
        const color = d['color_' + (i + 1)].split('|');
        colorsGroups.push({ h: +color[0], s: +color[1], l: +color[2] });
      }
    }
    return {
      id: `${d.artist}-${d.id}`,
      artist: d.artist,
      colors: colorsGroups
    };
  }).then(d => {
    set(d);
  });
});
