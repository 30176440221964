<script>
  import { createEventDispatcher } from "svelte";
  import { link } from "../../utils/Router.svelte";
  const dispatch = createEventDispatcher();

  import Button from "./Button.svelte";
  import ColorChip from "./ColorChip.svelte";

  export let colors = [];
  export let collectionLink = "";
</script>

<style lang="scss">
  @import "./styles/theme.scss";
  .preview {
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translate(-50%, 0);
  }
  .preview__container {
    background-color: $c-white;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.12);
    max-width: 400px;
    display: flex;
    align-items: center;
    padding: 10px 15px;
    box-sizing: border-box;
  }
  .preview__colors {
    display: flex;
    flex-wrap: wrap;
    margin-right: 15px;
    margin-bottom: 7px;
  }
  .preview__header {
    margin-right: 10px;
    display: flex;
    flex: none;
    color: $c-gray-4;
  }
</style>

<div class="preview">
  <div class="preview__container">
    <div class="preview__header">Collection</div>
    <div class="preview__colors">
      {#each colors as d (d.id)}
        <ColorChip
          color={d.hex}
          tip="Remove from collection"
          isCompact
          on:click={() => {
            dispatch('select', d.id);
          }} />
      {/each}
    </div>
    <Button href={link(collectionLink)} isCompact tip="Export colors">
      Export
    </Button>
    <Button
      isCompact
      tip="Clear collection"
      on:click={() => {
        dispatch('clear');
      }}>
      Clear
    </Button>
  </div>
</div>
