import {
  writable,
  derived
} from 'svelte/store';
import {
  getInitialParams
} from '../utils/Router.svelte';
import routes from '../routes';
import {
  forEach,
  split
} from 'lodash-es';

// init state from url
export const colorDetailLevel = writable(5);
export const selectedArtist = writable('Andy Warhol');
export const selectedColorId = writable('');
export const showRelativeColorSize = writable(false);
export const collectedColorIds = createCollectedColorIds();

function createCollectedColorIds() {
  const colorIds = writable([]);
  return {
    subscribe: colorIds.subscribe,
    set: colorIds.set,
    change: d => {
      colorIds.update(items => {
        let index = items.indexOf(d);
        if (index > -1) {
          return [...items.slice(0, index), ...items.slice(index + 1)];
        } else {
          return [...items, d];
        }
      });
    },
    addMultiple: ids => {
      colorIds.update(items => {
        let newItems = [...items];
        forEach(ids, d => {
          let index = newItems.indexOf(d);
          if (index === -1) {
            newItems = [...newItems, d];
          }
        })
        return newItems
      });
    },
    removeMultiple: ids => {
      colorIds.update(items => {
        let newItems = [...items];
        forEach(ids, d => {
          let index = newItems.indexOf(d);
          if (index > -1) {
            newItems = [...newItems.slice(0, index), ...newItems.slice(index + 1)];
          }
        })
        return newItems
      })
    },
    clear: d => {
      colorIds.set([]);
    }
  };
}

const routeParamter = {
  selectedArtist: {
    store: selectedArtist,
    func: undefined
  },
  selectedColorId: {
    store: selectedColorId,
    func: undefined
  },
  collectedColorIds: {
    store: collectedColorIds,
    func: d => d.split('|')
  }
};

// get state from route
forEach(getInitialParams(routes), ({
  key,
  value
}) => {
  if (value) {
    const func = routeParamter[key].func;
    const variable = routeParamter[key].store;
    if (func) {
      variable.set(func(value));
    } else {
      variable.set(value);
    }
  }
});

export const selectedArtistAndColor = derived(
  [selectedArtist, selectedColorId],
  ([$selectedArtist, $selectedColorId]) => {
    return {
      artist: $selectedArtist,
      color: $selectedColorId
    };
  }
);

export const artistLink = derived(
  [selectedArtist, selectedColorId],
  ([$selectedArtist, $selectedColorId]) =>
  `/artists/${$selectedArtist}${
      decodeURI($selectedColorId) !== '' ? `/${$selectedColorId}` : ''
    }`
);

export const collectionLink = derived(
  [collectedColorIds],
  ([$collectedColorIds]) =>
  `/export${$collectedColorIds.length ? '/' : ''}${$collectedColorIds.join(
      '|'
    )}`
);