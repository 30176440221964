<script>
  import EffectButton from "./EffectButton.svelte";

  export let text = "";
  export let onDarkBackground = false;

  function copyToClipboard(text) {
    var dummy = document.createElement("textarea");
    document.body.appendChild(dummy);
    dummy.value = text;
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
  }
</script>

<style lang="scss">
  @import "./styles/theme.scss";
  button {
    width: 20px;
    height: 20px;
    background-color: transparent;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 15 18' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath d='m11 .75h-9c-.825 0-1.5.675-1.5 1.5v10.5h1.5v-10.5h9v-1.5m2.25 3h-8.25c-.825 0-1.5.675-1.5 1.5v10.5c0 .825.675 1.5 1.5 1.5h8.25c.825 0 1.5-.675 1.5-1.5v-10.5c0-.825-.675-1.5-1.5-1.5m0 12h-8.25v-10.5h8.25v10.5' fill='%23000' opacity='.4'/%3E%3C/g%3E%3C/svg%3E");
    border: none;
    cursor: pointer;
  }
  button:focus {
    outline: 0;
  }
  .on-dark-background {
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 15 18' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath d='m11 .75h-9c-.825 0-1.5.675-1.5 1.5v10.5h1.5v-10.5h9v-1.5m2.25 3h-8.25c-.825 0-1.5.675-1.5 1.5v10.5c0 .825.675 1.5 1.5 1.5h8.25c.825 0 1.5-.675 1.5-1.5v-10.5c0-.825-.675-1.5-1.5-1.5m0 12h-8.25v-10.5h8.25v10.5' fill='%23FFF' opacity='.4'/%3E%3C/g%3E%3C/svg%3E");
  }
</style>

<EffectButton>
  <button
    class:on-dark-background={onDarkBackground}
    on:click={() => {
      copyToClipboard(text);
    }} />
</EffectButton>
