<script>
  export let id = "";
  export let value = "";
  export let title = "";
</script>

<style lang="scss">
  @import "./styles/theme.scss";
  label {
    color: $c-gray-4;
    margin-right: $sp-small/2;
  }
  input {
    margin-right: $sp-small;
    width: 25px;
  }
</style>

<label>
  {title}
  <input type="text" name={id} {value} />
</label>
