<script>
  import Artist from "../components/ui/Artist.svelte";
  import { artistsWithColors } from "../data/dataAPI";
  import { selectedArtist, selectedColorId } from "../state/uiState";
  import { push } from "../utils/Router.svelte";

  let listEl = null;
  let artistEl = null;

  $: {
    if (listEl && artistEl) {
      if (
        artistEl.offsetTop > listEl.scrollTop + listEl.offsetHeight ||
        artistEl.offsetTop < listEl.scrollTop
      ) {
        scrollToEl(
          listEl,
          artistEl.offsetTop -
            listEl.offsetTop -
            listEl.offsetHeight / 2 +
            artistEl.offsetHeight / 2,
          300
        );
      }
    }
  }

  function scrollToEl(element, to, duration) {
    if (duration <= 0) return;
    const difference = to - element.scrollTop;
    const perTick = (difference / duration) * 10;

    setTimeout(() => {
      element.scrollTop = element.scrollTop + perTick;
      scrollToEl(element, to, duration - 10);
    }, 10);
  }
</script>

<style>
  .artist-list__container {
    width: 300px;
    overflow: auto;
  }
  .artist-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }
</style>

<div class="artist-list__container" bind:this={listEl}>
  <ul class="artist-list" bind:this={listEl}>
    {#each $artistsWithColors as artist (artist.name)}
      {#if artist.isSelected}
        <li bind:this={artistEl}>
          <Artist
            name={artist.name}
            colors={artist.colors}
            isActive={artist.isSelected}
            on:click={() => {
              selectedArtist.set(artist.name);
              push('/artists/' + artist.name);
            }} />
        </li>
      {:else}
        <li>
          <Artist
            name={artist.name}
            colors={artist.colors}
            isActive={artist.isSelected}
            on:click={() => {
              selectedArtist.set(artist.name);
              push('/artists/' + artist.name);
            }} />
        </li>
      {/if}
    {/each}
  </ul>
</div>
