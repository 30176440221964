<script>
  export let url = "";
  export let text = "";
  export let hashtags = "";

  $: encodedUrl = encodeURIComponent(url);
  $: encodedText = encodeURIComponent(text.replace(/\s+/g, " "));
  $: encodedHashtags = encodeURIComponent(hashtags);
</script>

<style lang="scss">
  @import "./styles/theme.scss";
  .sharing {
    margin-left: $sp-medium;
    padding-left: $sp-medium;
    align-items: center;
    display: flex;
    border-left: 1px solid $c-gray-3;
    ul {
      padding: 0;
      margin: 0;
      list-style: none;
    }
    li {
      display: inline-block;
    }
    .sharing__button {
      background-repeat: no-repeat;
      background-position: center;
      a {
        width: 30px;
        height: 30px;
        transition: opacity 0.2s;
        display: block;
        color: transparent;
        line-height: 0;
        padding: 5px;
        opacity: 0.3;

        .sharing__text {
          display: none;
        }
      }
      a:hover {
        opacity: 1;
      }
    }

    .sharing__icon {
      position: relative;
      width: 30px;
      height: 30px;
      background-position-x: center;
      display: inline-block;
      padding-left: 0;
      &.is-facebook {
        background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iRWJlbmVfMSIgZGF0YS1uYW1lPSJFYmVuZSAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI%2BPHRpdGxlPmljb25fX2ZhY2Vib29rPC90aXRsZT48cGF0aCBkPSJNMTUuNzMsMTIuMzRsLTIuNzMuMXY3Ljg4SDkuODFWMTIuNDRINy4yVjkuMzRIOS45MVY3LjA5YTMuOTQsMy45NCwwLDAsMSwxLjEtMyw0LDQsMCwwLDEsMi44NS0xcS44NSwwLDEuNDksMGwuODYsMFY1Ljg4SDE0LjZhMS4zOCwxLjM4LDAsMCwwLTEuMi40MywxLjg0LDEuODQsMCwwLDAtLjMsMS4wN1Y5LjI0aDNaIi8%2BPC9zdmc%2B);
      }
      &.is-twitter {
        background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iRWJlbmVfMSIgZGF0YS1uYW1lPSJFYmVuZSAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI%2BPHRpdGxlPmljb25fX3R3aXR0ZXI8L3RpdGxlPjxwYXRoIGQ9Ik0yMC43LDYuMTRhNy42Niw3LjY2LDAsMCwxLS44MywxLjEsNS40NSw1LjQ1LDAsMCwxLTEuMTMuOTR2LjVhMTAuNTIsMTAuNTIsMCwwLDEtLjY2LDMuNThBMTAuMjksMTAuMjksMCwwLDEsMTIuODgsMThhOS44NCw5Ljg0LDAsMCwxLTQuNDQsMSwxMCwxMCwwLDAsMS0zLS40NUE5LjQ1LDkuNDUsMCwwLDEsMi43LDE3LjEzYTEuNDgsMS40OCwwLDAsMCwuNDQuMDloLjQ0QTcuMjksNy4yOSwwLDAsMCw2LDE2LjgzYTcuNjIsNy42MiwwLDAsMCwyLjExLTEuMTVBMy4zNSwzLjM1LDAsMCwxLDYsMTVhMy44NCwzLjg0LDAsMCwxLTEuMzEtMS44Mi44Ny44NywwLDAsMCwuMzUuMDlINS45YTEuNiwxLjYsMCwwLDAsLjQ5LS4wOUEzLjM3LDMuMzcsMCwwLDEsNC4zMSwxMS45YTMuNjEsMy42MSwwLDAsMS0uODQtMi4zNCwzLjMzLDMuMzMsMCwwLDAsLjguMzUsMy4zNywzLjM3LDAsMCwwLDEsLjEzQTMuMzEsMy4zMSwwLDAsMSw0LDguNzcsMy41MywzLjUzLDAsMCwxLDMuNTcsNywzLjgsMy44LDAsMCwxLDMuNyw2YTMuNTYsMy41NiwwLDAsMSwuMzUtLjg2QTEwLjM0LDEwLjM0LDAsMCwwLDUuNTQsNi42MywxMC43MiwxMC43MiwwLDAsMCw3LjMzLDcuOGExMC41MiwxMC41MiwwLDAsMCwyLC44QTEwLjExLDEwLjExLDAsMCwwLDExLjU2LDlhMS40OCwxLjQ4LDAsMCwxLS4wOS0uNDRxMC0uMjIsMC0uNDJhMy41OSwzLjU5LDAsMCwxLDEuMDgtMi42MiwzLjY2LDMuNjYsMCwwLDEsNC4xMi0uNzYsNC4yNSw0LjI1LDAsMCwxLDEuMjMuODUsMTEuOCwxMS44LDAsMCwwLDEuMjQtLjM3LDYuODcsNi44NywwLDAsMCwxLjEtLjUxLDMuNTIsMy41MiwwLDAsMS0uNjUsMS4xNyw0LjIyLDQuMjIsMCwwLDEtMSwuODcsNi4yOSw2LjI5LDAsMCwwLDEuMS0uMjFBOS4yMiw5LjIyLDAsMCwwLDIwLjcsNi4xNFoiLz48L3N2Zz4%3D);
      }
      &.is-linkedin {
        background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIwLjEuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkViZW5lXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMjQgMjQ7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHRpdGxlPmljb25fX3R3aXR0ZXI8L3RpdGxlPgo8Zz4KCTxwYXRoIGQ9Ik0xOC43LDRjMC40LDAsMC43LDAuMSwwLjksMC40UzIwLDQuOSwyMCw1LjJ2MTMuNGMwLDAuNC0wLjEsMC43LTAuNCwxYy0wLjIsMC4zLTAuNiwwLjQtMC45LDAuNEg1LjRjLTAuNCwwLTAuNy0wLjEtMS0wLjQKCQlDNC4xLDE5LjMsNCwxOSw0LDE4LjZWNS4yYzAtMC40LDAuMS0wLjcsMC40LTAuOVM1LDQsNS40LDRIMTguN3ogTTcsOC44QzcuMiw5LDcuNSw5LjEsNy45LDkuMWMwLjQsMCwwLjctMC4xLDAuOS0wLjQKCQlDOSw4LjUsOS4xLDguMiw5LjEsNy45QzkuMSw3LjYsOSw3LjMsOC44LDdTOC4zLDYuNyw3LjksNi43UzcuMyw2LjgsNyw3UzYuNyw3LjYsNi43LDcuOUM2LjcsOC4yLDYuOCw4LjUsNyw4Ljh6IE05LDE3LjN2LTcuMUg2LjcKCQl2Ny4xSDl6IE0xNy4zLDE3LjN2LTQuMmMwLTEtMC4yLTEuOC0wLjctMi4zYy0wLjUtMC41LTEuMS0wLjgtMS45LTAuOGMtMC44LDAtMS41LDAuNC0yLjEsMS4ydi0xaC0yLjN2Ny4xaDIuM3YtNAoJCWMwLTAuMywwLTAuNSwwLjEtMC42YzAuMi0wLjYsMC42LTAuOCwxLjItMC44YzAuOCwwLDEuMiwwLjUsMS4yLDEuNXYzLjlIMTcuM3oiLz4KPC9nPgo8L3N2Zz4K);
      }
    }
  }
</style>

<ul class="sharing">
  <li class="sharing__button twitter">
    <a
      href={`https://twitter.com/intent/tweet?text=${encodedText}&url=${encodedUrl}&hashtags=${encodedHashtags}`}
      target="_blank"
      title="Share on Twitter"
      role="button"
      aria-label="Share on Twitter"
      rel="noopener noreferrer">
      <span class="sharing__icon is-twitter" />
      <span class="sharing__text">tweet</span>
    </a>
  </li>
  <li class="sharing__button facebook">
    <a
      href={`https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`}
      target="_blank"
      title="Share on Facebook"
      role="button"
      aria-label="Share on Facebook"
      rel="noopener noreferrer">
      <span class="sharing__icon is-facebook" />
      <span class="sharing__text">share</span>
    </a>
  </li>
  <li class="sharing__button linkedin">
    <a
      href={`https://www.linkedin.com/cws/share?url=${encodedUrl}`}
      target="_blank"
      title="Share on LinkedIn"
      role="button"
      aria-label="Share on LinkedIn"
      rel="noopener noreferrer">
      <span class="sharing__icon is-linkedin" />
      <span class="sharing__text">share</span>
    </a>
  </li>
</ul>
