<script>
  let isClicked = false;
</script>

<style>
  .effect-button {
    position: relative;
    display: flex;
    padding: 0;
    border: none;
    background: none;
    transition: color 0.7s;
  }

  .effect-button::after {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -35px 0 0 -35px;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    content: "";
    opacity: 0;
    pointer-events: none;
    box-shadow: inset 0 0 0 35px rgba(111, 148, 182, 0.4);
  }
  .effect-button.is-clicked::after {
    -webkit-animation: anim-effect 0.5s ease-out forwards;
    animation: anim-effect 0.5s ease-out forwards;
  }

  @-webkit-keyframes anim-effect {
    0% {
      opacity: 1;
      -webkit-transform: scale3d(0.4, 0.4, 1);
      transform: scale3d(0.4, 0.4, 1);
    }
    80% {
      box-shadow: inset 0 0 0 2px rgba(111, 148, 182, 0.8);
      opacity: 0.1;
    }
    100% {
      box-shadow: inset 0 0 0 2px rgba(111, 148, 182, 0.8);
      opacity: 0;
      -webkit-transform: scale3d(1.2, 1.2, 1);
      transform: scale3d(1.2, 1.2, 1);
    }
  }

  @keyframes anim-effect {
    0% {
      opacity: 1;
      -webkit-transform: scale3d(0.4, 0.4, 1);
      transform: scale3d(0.4, 0.4, 1);
    }
    80% {
      box-shadow: inset 0 0 0 2px rgba(111, 148, 182, 0.8);
      opacity: 0.1;
    }
    100% {
      box-shadow: inset 0 0 0 2px rgba(111, 148, 182, 0.8);
      opacity: 0;
      -webkit-transform: scale3d(1.2, 1.2, 1);
      transform: scale3d(1.2, 1.2, 1);
    }
  }
</style>

<div
  class="effect-button"
  class:is-clicked={isClicked}
  on:click={() => {
    isClicked = true;
    window.setTimeout(function() {
      isClicked = false;
    }, 600);
  }}>

  <slot />
</div>
