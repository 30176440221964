<script>
  export let tip = "";
</script>

<style lang="scss">
  @import "./styles/theme.scss";
  span {
    display: inline-block;
    padding: 2px 5px;
    background-color: $c-gray-2;
    margin: 0 3px 3px 0;
    border-radius: 4px;
    cursor: pointer;
  }
  span:hover {
    background-color: $c-gray-3;
    transform: scale(1.1);
    transform-origin: 0% 50%;
  }
</style>

<span title={tip} on:click>
  <slot />
</span>
