<script>
  import { link } from "../utils/Router.svelte";

  import ColorPile from "../components/ui/ColorPile.svelte";
  import Sharing from "../components/ui/Sharing.svelte";

  import { highlightColor } from "../data/dataAPI.js";
  import {
    collectedColorIds,
    selectedArtistAndColor,
    artistLink,
    collectionLink
  } from "../state/uiState.js";

  export let activeItem = "";

  $: backgroundColor = $highlightColor.backgroundColor;
  $: textColor = $highlightColor.textColor;
</script>

<style lang="scss">
  @import "./styles/theme.scss";
  .header {
    height: 50px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    background-color: #fff;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.12);
    z-index: 2000;
    box-sizing: border-box;
    position: relative;
  }
  .header a {
    text-decoration: none;
  }
  .header h1 {
    font-weight: $fw-medium;
    span {
      font-size: $fs-small;
      color: $c-gray-4;
      margin-top: -6px;
      margin-left: -2px;
      vertical-align: super;
    }
  }
  .header__links {
    display: flex;
    a {
      color: $c-gray-4;
      padding: 14px;
      text-decoration: none;

      span {
        padding: 3px 9px;
        background-color: $c-gray-2;
        border-radius: 50px;
      }
    }
    a.is-usable {
      span {
        background-color: $c-black;
        color: $c-white;
      }
    }
    a.is-active {
      color: $c-black;
      font-weight: $fw-medium;
      border-bottom: 2px solid $c-black;
    }
  }
</style>

<header class="header">
  <a href={link($artistLink)} class:is-active={activeItem === 'Artists'}>
    <h1>
      <ColorPile {textColor} {backgroundColor} tip={backgroundColor}>
        Color
      </ColorPile>
      Theft
      <span>Alpha</span>
    </h1>
  </a>
  <nav class="header__links">
    <a href={link($artistLink)} class:is-active={activeItem === 'Artists'}>
      Artists
    </a>
    <a
      href={link($collectionLink)}
      class:is-active={activeItem === 'Export'}
      class:is-usable={$collectedColorIds.length !== 0}>
      Export
      <span>{$collectedColorIds.length}</span>
    </a>
    <a href={link('/about')} class:is-active={activeItem === 'About'}>About</a>
    <Sharing
      url="https://colortheft.com/"
      text="Color Theft from @laessr lets you steal colors from your favorite
      artists and create your own palettes. Enjoy the first tablet and desktop
      version."
      hashtags="art #colors #threejs" />
  </nav>
</header>
