<script>
  import { createEventDispatcher } from "svelte";
  import { forEach, keyBy } from "lodash-es";

  import {
    selectedColorId,
    selectedArtist,
    collectedColorIds
  } from "../state/uiState";
  import {
    selectedColorDetails,
    selectedColorInCollection
  } from "../data/dataAPI";

  import { push } from "../utils/Router.svelte";

  import ColorDetail from "../components/ui/ColorDetail.svelte";
  import ColorChip from "../components/ui/ColorChip.svelte";
  import ArtistPile from "../components/ui/ArtistPile.svelte";
  import Divider from "../components/ui/Divider.svelte";
  import FavButton from "../components/ui/FavButton.svelte";
  import CloseButton from "../components/ui/CloseButton.svelte";

  let hsl,
    hex,
    rgb,
    textColor = "";

  let artists,
    artistLinks,
    otherLinks = [];
  let isVisible = false;

  let artistLinksAreFaved = false;
  let otherLinksAreFaved = false;

  $: {
    if ($selectedColorId !== "" && $selectedColorDetails) {
      hsl = $selectedColorDetails.hsl;
      hex = $selectedColorDetails.hex;
      rgb = $selectedColorDetails.rgb;
      textColor = $selectedColorDetails.textColor;
      artists = $selectedColorDetails.artists || [];
      artistLinks = $selectedColorDetails.artistLinks || [];
      otherLinks = $selectedColorDetails.otherLinks || [];

      const collectedColorIdsById = {};
      forEach($collectedColorIds, d => {
        collectedColorIdsById[d] = 1;
      });

      artistLinksAreFaved = true;
      for (let i = 0; i < artistLinks.length; i++) {
        const d = artistLinks[i];
        if (collectedColorIdsById[d.id] === undefined) {
          artistLinksAreFaved = false;
          break;
        }
      }

      otherLinksAreFaved = true;
      for (let i = 0; i < otherLinks.length; i++) {
        const d = otherLinks[i];
        if (collectedColorIdsById[d.id] === undefined) {
          otherLinksAreFaved = false;
          break;
        }
      }

      isVisible = true;
    } else {
      isVisible = false;
    }
  }
</script>

<style lang="scss">
  @import "./styles/theme.scss";

  .color-details {
    position: relative;
    height: 100%;
    width: 240px;
    background-color: #fff;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.12);
    overflow: auto;
    z-index: 1000;
  }
  .color-details__container {
    height: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
  }
  .color-details h3 {
    font-weight: $fw-medium;
    margin-bottom: $sp-small;
  }
  .color-details h4 {
    color: $c-gray-4;
    margin-bottom: $sp-base;
  }
  .color-details__wrapper {
    display: flex;
    margin: $sp-semi-medium;
    flex-direction: column;
  }
  .color-details__header {
    position: relative;
    z-index: 1000;
    height: 10px;
  }
  .color-details__header-controls {
    display: flex;
    align-items: center;
    position: absolute;
    right: 25px;
    top: 10px;
  }
  .color-details__expand-scroll {
    flex: 1;
    overflow: auto;
  }
  .color-details__controls {
    display: flex;
    justify-content: center;
    // align-self: flex-end;
    margin-bottom: $sp-semi-medium;
  }
  .color-details__colors {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
  }
  .color-details__connected-colors {
    margin-bottom: 5px;
  }
  .color-details__space {
    width: 5px;
  }
</style>

{#if isVisible}
  <div class="color-details">
    <div class="color-details__container">
      <header class="color-details__header">
        <div class="color-details__header-controls">
          <FavButton
            tip={$selectedColorInCollection ? 'Remove' : 'Add'}
            isBig
            isFaved={$selectedColorInCollection}
            on:click={() => {
              collectedColorIds.change($selectedColorId);
            }} />
          <span class="color-details__space" />
          <CloseButton
            tip="Close"
            isBig
            on:click={() => {
              selectedColorId.set('');
              push('/artists/' + $selectedArtist);
            }} />
        </div>
      </header>
      <div class="color-details__wrapper">
        <ColorDetail {hsl} {hex} {rgb} {textColor} />
      </div>
      {#if artistLinks.length !== 0 || otherLinks.length !== 0}
        <Divider />
        <div class="color-details__wrapper color-details__connected-colors">
          <h3>Related Colors</h3>

          {#if artistLinks.length !== 0}
            <h4>Paintings by {$selectedArtist}</h4>
            <div class="color-details__colors">
              {#each artistLinks as d (d.id)}
                <ColorChip
                  color={d.hex}
                  tip={d.hex}
                  on:click={() => {
                    selectedColorId.set(d.id);
                    push('/artists/' + $selectedArtist + '/' + d.id);
                  }} />
              {/each}
              <span class="color-details__space" />
              <FavButton
                isFaved={artistLinksAreFaved}
                isForMany
                on:click={() => {
                  if (artistLinksAreFaved) {
                    collectedColorIds.removeMultiple(artistLinks.map(d => d.id));
                  } else {
                    collectedColorIds.addMultiple(artistLinks.map(d => d.id));
                  }
                }} />
            </div>
          {/if}
          {#if otherLinks.length !== 0}
            <h4>Paintings by other artists</h4>
            <div class="color-details__colors">
              {#each otherLinks as d (d.id)}
                <ColorChip
                  color={d.hex}
                  tip={d.hex}
                  on:click={() => {
                    selectedColorId.set(d.id);
                    push('/artists/' + $selectedArtist + '/' + d.id);
                  }} />
              {/each}
              <span class="color-details__space" />
              <FavButton
                isFaved={otherLinksAreFaved}
                isForMany
                on:click={() => {
                  if (otherLinksAreFaved) {
                    collectedColorIds.removeMultiple(otherLinks.map(d => d.id));
                  } else {
                    collectedColorIds.addMultiple(otherLinks.map(d => d.id));
                  }
                }} />
            </div>
          {/if}
        </div>
      {/if}
      <Divider />
      <div
        class="color-details__wrapper color-details__expand-scroll
        color-details__artists">
        {#if artists.length !== 0}
          <h3>Used by Artists</h3>
          <div class="color-details__expand-scroll">
            {#each artists as d (d)}
              <ArtistPile
                tip={`Show colors of ${d}`}
                on:click={() => {
                  selectedArtist.set(d);
                  push('/artists/' + d + '/' + $selectedColorId);
                }}>
                {d}
              </ArtistPile>
            {/each}
          </div>
        {/if}
      </div>
    </div>
  </div>
{/if}
