<script>
  import CopyButton from "./CopyButton.svelte";
  export let text = "";
</script>

<style lang="scss">
  @import "./styles/theme.scss";
  .export-field {
    font-size: $fs-semi-medium;
    display: flex;
    background-color: $c-white;
    border-radius: 4px;
    box-sizing: border-box;
    // min-width: 100%;
    margin-bottom: 10px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12);
  }
  .export-field__control {
    display: inline;
    background-color: $c-gray-2;
    margin-right: 5px;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 4px 0 0 4px;
  }
  .export-field__text {
    padding: 10px;
    box-sizing: border-box;
  }
</style>

<div class="export-field">
  <div class="export-field__control">
    <CopyButton {text} />
  </div>
  <div class="export-field__text">{text}</div>
</div>
