<script context="module">
  import { writable, derived } from "svelte/store";

  class RouteItem {
    constructor(route) {
      const component = route.component || undefined;
      const path = route.path || "";

      // Path must be a regular or expression, or a string starting with '/' or '*'
      if (
        !path ||
        (typeof path == "string" &&
          (path.length < 1 ||
            (path.charAt(0) != "/" && path.charAt(0) != "*"))) ||
        (typeof path == "object" && !(path instanceof RegExp))
      ) {
        throw Error('Invalid value for "path" argument');
      }

      const { pattern, keys } = regexparam(path);

      if (component) {
        this.name = route.name;
        this.component = component;
        this.overflow = route.overflow;
      }
      if (route.redirect) {
        this.redirect = route.redirect;
      }

      this.path = path;
      this._pattern = pattern;
      this._keys = keys;
    }

    match(path) {
      // Check if the pattern matches
      const matches = this._pattern.exec(path);
      if (matches === null) {
        return null;
      }

      // If the input was a regular expression, this._keys would be false, so return matches as is
      if (this._keys === false) {
        return matches;
      }

      const out = {};
      let i = 0;
      while (i < this._keys.length) {
        out[this._keys[i]] = matches[++i] || null;
      }
      return out;
    }
  }

  export function getLocation() {
    const hashPosition = window.location.href.indexOf("#/");
    let location =
      hashPosition > -1 ? window.location.href.substr(hashPosition + 1) : "/";

    // Check if there's a querystring
    const qsPosition = location.indexOf("?");
    let querystring = "";
    if (qsPosition > -1) {
      querystring = location.substr(qsPosition + 1);
      location = location.substr(0, qsPosition);
    }

    return { location, querystring };
  }

  export const loc = writable(getLocation(), function start(set) {
    const update = () => {
      set(getLocation());
    };
    window.addEventListener("hashchange", update, false);

    return function stop() {
      window.removeEventListener("hashchange", update, false);
    };
  });

  export function push(location) {
    if (
      !location ||
      location.length < 1 ||
      (location.charAt(0) != "/" && location.indexOf("#/") !== 0)
    ) {
      throw Error("Invalid parameter location");
    }

    // Execute this code when the current call stack is complete
    setTimeout(() => {
      window.location.hash = (location.charAt(0) == "#" ? "" : "#") + location;
    }, 0);
  }

  export function link(href) {
    if (href.charAt(0) != "/") {
      throw Error('Invalid value for "href" attribute');
    }
    return "#" + href;
  }

  function findRouteToLocation(routesList, location) {
    let route = null;
    let i = 0;
    while (!route && i < routesList.length) {
      const match = routesList[i].match(location);
      if (match) {
        route = { route: routesList[i], params: match };
      }
      i++;
    }
    return route;
  }

  export function setInitialRoute(routes) {
    const location = getLocation().location;

    const routesList = getRoutesList(routes, true);
    const route = findRouteToLocation(routesList, location);

    if (route && route.route.redirect !== undefined) {
      push(route.route.redirect);
      loc.set({ location: route.route.redirect, querystring: "" });
    }
  }

  export function getInitialParams(routes) {
    const location = getLocation().location;
    const routesList = getRoutesList(routes, true);
    const route = findRouteToLocation(routesList, location);

    if (route) {
      return map(route.params, (v, k) => ({
        key: k,
        value: v !== null ? decodeURI(v) : v
      }));
    }
  }

  function getRoutesList(routes, includeComponents = false) {
    const routesList = [];
    forEach(routes, d => {
      if (includeComponents || d.component) {
        routesList.push(new RouteItem(d));
      }
    });
    return routesList;
  }
</script>

<script>
  import regexparam from "regexparam";
  import { uniqBy, map, filter, forEach } from "lodash-es";

  export let routes = [];
  export let activeComponent = null;
  export let viewProps = {};

  let routesList = [];

  $: {
    routesList = getRoutesList(routes);
  }
  $: componentList = uniqBy(
    filter(routesList, d => d.component && d.name),
    "name"
  );

  $: {
    // Find a route matching the location
    activeComponent = null;
    const route = findRouteToLocation(routesList, $loc.location);
    if (route) {
      activeComponent = route.route.name;
    }
  }
</script>

<style>
  .router-component {
    display: none;
    width: 100%;
    height: 100%;
  }
  .router-component.is-visible {
    display: block;
  }
</style>

{#each componentList as c (c.name)}
  <div
    class="router-component"
    class:is-visible={activeComponent == c.name}
    style={`overflow-y: ${c.overflow}`}>
    <svelte:component this={c.component} {...viewProps} />
  </div>
{/each}
