const toHex = x => {
  const hex = x.toString(16);
  return hex.length === 1 ? '0' + hex : hex;
};

export const hslToRGB = function(h, s, l) {
  h /= 360;
  s /= 100;
  l /= 100;
  let r, g, b;
  if (s === 0) {
    r = g = b = l; // achromatic
  } else {
    const hue2rgb = (p, q, t) => {
      if (t < 0) t += 1;
      if (t > 1) t -= 1;
      if (t < 1 / 6) return p + (q - p) * 6 * t;
      if (t < 1 / 2) return q;
      if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
      return p;
    };
    const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    const p = 2 * l - q;
    r = hue2rgb(p, q, h + 1 / 3);
    g = hue2rgb(p, q, h);
    b = hue2rgb(p, q, h - 1 / 3);
  }
  return {
    r: Math.round(r * 255),
    g: Math.round(g * 255),
    b: Math.round(b * 255)
  };
};

export const rgbToHex = function(r, g, b) {
  return `${toHex(r)}${toHex(g)}${toHex(b)}`;
};

export const hextoHexInt = function(hex) {
  return parseInt(`0x${hex}`);
};

export const luminanace = function(r, g, b) {
  var a = [r, g, b].map(function(v) {
    v /= 255;
    return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
  });
  return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
};

export const contrast = function(rgb1, rgb2) {
  return (
    (luminanace(rgb1.r, rgb1.g, rgb1.b) + 0.05) /
    (luminanace(rgb2.r, rgb2.g, rgb2.b) + 0.05)
  );
};

export const randomRGB = function() {
  return {
    r: Math.floor(Math.random() * 255),
    g: Math.floor(Math.random() * 255),
    b: Math.floor(Math.random() * 255)
  };
};
