<script>
  import App from "./DesktopApp.svelte";
  import MobileDisclaimer from "./components/ui/MobileDisclaimer.svelte";
  let width, height;
</script>

<style>
  div {
    overflow: hidden;
    width: 100%;
    height: 100%;
  }
</style>

<div bind:clientWidth={width} bind:clientHeight={height}>
  {#if width >= 1024}
    <App {width} {height} />
  {:else}
    <MobileDisclaimer />
  {/if}
</div>
