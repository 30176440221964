<script>
  import { createEventDispatcher } from "svelte";
  import ColorDetail from "./ColorDetail.svelte";

  const dispatch = createEventDispatcher();

  export let id = "";
  export let hsl = "";
  export let hex = "";
  export let rgb = "";
  export let textColor = "";
</script>

<style lang="scss">
  @import "./styles/theme.scss";
  .collection-item {
    position: relative;
    background: $c-white;
    border-radius: 4px;
    padding: 15px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.12);
    margin: 0 20px 20px 0;
    display: inline-block;
  }
  .collection-item__remove {
    display: none;
    position: absolute;
    width: 30px;
    height: 30px;
    background-color: $c-white;
    border-radius: 30px;
    right: -12px;
    top: -12px;
    padding: 6px;
    box-sizing: border-box;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12);

    &:hover {
      background-color: $c-black;
      .close::before,
      .close::after {
        background: $c-white;
      }
    }
  }
  .close {
    position: relative;
    display: inline-block;
    width: 18px;
    height: 18px;
    overflow: hidden;

    &::before,
    &::after {
      content: "";
      position: absolute;
      height: 2px;
      width: 100%;
      top: 50%;
      left: 0;
      margin-top: -1px;
      background: $c-black;
    }
    &::before {
      transform: rotate(45deg);
    }
    &::after {
      transform: rotate(-45deg);
    }
  }
  .collection-item:hover {
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.12);
    transform: scale(1.02);
    cursor: pointer;

    .collection-item__remove {
      display: inline-block;
    }
  }
</style>

<div class="collection-item">
  <div
    class="collection-item__remove"
    on:click={() => {
      dispatch('remove', id);
    }}>
    <div class="close" />
  </div>
  <ColorDetail {hsl} {hex} {rgb} {textColor} />
</div>
