<script>
  import { orderBy } from "lodash-es";
  import {
    activeArtistColors,
    collectedColorDetails,
    aggregatedColorsCompact
  } from "../data/dataAPI";
  import {
    showRelativeColorSize,
    selectedColorId,
    selectedArtist,
    collectedColorIds,
    collectionLink
  } from "../state/uiState";
  import { push } from "../utils/Router.svelte";
  import Color3d from "../components/vis/Color3d.svelte";
  import CollectionPreview from "../components/ui/CollectionPreview.svelte";

  export let width = 0;
  export let height = 0;
  export let offsetX = 0;
  export let offsetY = 0;

  $: collectionHasItems = $collectedColorIds.length !== 0;
  $: sortedColors = orderBy($collectedColorDetails, "hsl.l");

  function handleSelectColor(id) {
    if ($selectedColorId !== id) {
      selectedColorId.set(id);
      push("/artists/" + $selectedArtist + "/" + id);
    } else {
      selectedColorId.set("");
      push("/artists/" + $selectedArtist);
    }
  }
</script>

<style lang="scss">
  @import "./styles/theme.scss";

  .stage {
    position: relative;
    height: 100%;
  }
</style>

<div class="stage">
  <Color3d
    {width}
    {height}
    {offsetX}
    {offsetY}
    selectedColorId={$selectedColorId}
    activeColors={$activeArtistColors}
    colors={$aggregatedColorsCompact}
    useRelativeSize={$showRelativeColorSize}
    on:select={d => {
      handleSelectColor(d.detail);
    }} />
  {#if collectionHasItems}
    <CollectionPreview
      colors={sortedColors}
      collectionLink={$collectionLink}
      on:select={d => {
        handleSelectColor(d.detail);
      }}
      on:clear={d => {
        collectedColorIds.clear();
      }} />
  {/if}
</div>
