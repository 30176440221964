<script>
  export let color = "";
  export let tip = "";
  export let isCompact = false;
</script>

<style>
  .color-chip {
    width: 20px;
    height: 20px;
    display: inline-block;
    border-radius: 20px;
    margin: 0 3px 3px 0;
    border: 1px solid rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }
  .is-compact {
    width: 20px;
    height: 20px;
    margin-right: -7px;
    margin-bottom: -7px;
  }
  .color-chip:hover {
    transform: scale(1.1);
    z-index: 1000;
  }
</style>

<span
  class="color-chip"
  class:is-compact={isCompact}
  style={`background-color: ${color}`}
  title={tip}
  on:click />
